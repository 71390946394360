<template>
  <b-modal id="edit-indice" ref="modal" centered title="Edit Indice //"
           title-class="font-18" hide-footer @shown="create" hide-header-close>

          <form id="form" method="POST" @submit.prevent="EditIndice">
            <div class="form-group mt-3 mb-0">
              <label class="pb-2"> Level:</label>
              <b-form-input placeholder="Level" type="number" v-model="indice.level"></b-form-input>
            </div>
            <div class="row mx-0">
            <div class="form-group col-xl-6 mt-3 ms-0 mb-0">
              <label class="pb-2"> Type:</label>
              <b-form-select class="form-select form-select-sm" :options="['text','audio','image']" type="text" placeholder="Type" v-model="indice.type"></b-form-select>
            </div>
            <div class="form-group col-xl-6 mt-3 ms-0 mb-0 d-flex">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="indice.is_solution"
                    name="checkbox-1"
                    class="me-2 align-self-end"
                    :unchecked-value="0"
                    :value="1"
                >
                  Solution
                </b-form-checkbox>

              </div>
            </div>
            <div class="form-group mt-3 mb-0">
              <label class="pb-2">Content:</label>
              <input v-if="indice.type == 'audio'"  class="form-control form-control-sm" type="file" accept="audio/*"  ref="file" />
              <input  v-else-if="indice.type == 'image'" class="form-control form-control-sm" type="file" accept="image/*"  ref="file" />
              <b-form-textarea rows="3" v-else v-model="indice.content"></b-form-textarea>
            </div>
            <div class="row mx-0">
              <div class="form-group col-xl-6 mt-3 ms-0 mb-0">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="indice.mode0"
                    name="checkbox-1"
                    class="me-2"
                    :unchecked-value="0"
                    :value="1"
                >
                  Confirmed Mode
                </b-form-checkbox>

              </div>
              <div class="form-group col-xl-6 mt-3 pe-0 mb-0">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="indice.mode1"
                    name="checkbox-1"
                    class="me-2"
                    :unchecked-value="0"
                    :value="1"
                >
                  Beginner Mode
                </b-form-checkbox>

              </div>
            </div>

            <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
          </form>
  </b-modal>
</template>

<script>

export default {
  name: "EditIndice",
  props:['indice_p'],
  data: function() {
    return {

      indice:{},

    }
  },

  methods: {
    create(){
      this.indice = {...this.indice_p}
    },

    EditIndice(){
      let formData = new FormData();
      formData.append('type', this.indice.type);
      formData.append('level', this.indice.level);
      formData.append('mode0', this.indice.mode0);
      formData.append('mode1', this.indice.mode1);
      formData.append('is_solution', this.indice.is_solution);
      if(this.indice.type == 'audio' || this.indice.type == 'image') {
        if(this.$refs.file.files.length) formData.append('content', this.$refs.file.files[0]);
      }
      else formData.append('content', this.indice.content);
      this.$http
          .post('indices/'+this.indice_p.id,formData)
          .then(response => {
            this.$emit('refresh-edit',response.data)
            this.$alertify.success(" success")
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
          })
    },
  }


}
</script>

<style scoped>

</style>