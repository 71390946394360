<template>
  <b-modal id="add-cadenas" title="Add Cadenas ///" ref="modal" title-class="font-22"
           hide-footer @shown="create" hide-header-close>
    <form id="form" method="POST" @submit.prevent="handleSubmit">
      <div class="form-group mt-3 mb-0">
        <label class="pb-2"> Cadena Id:</label>
        <b-form-input type="text" placeholder="Cadena Id" v-model="cadena.cadena_id"
                      :class="{ 'is-invalid': typesubmit && $v.cadena.cadena_id.$error }"></b-form-input>
        <div v-if="typesubmit && $v.cadena.cadena_id.$error" class="invalid-feedback">
          <span v-if="!$v.cadena.cadena_id.required"> The Cadena Id is required</span>
        </div>


      </div>
      <div class="form-group mt-3 mb-0">
        <label class="pb-2">Level:</label>
        <b-form-input type="number" v-model="cadena.level"  :class="{ 'is-invalid': typesubmit && $v.cadena.level.$error }"></b-form-input>
        <div v-if="typesubmit && $v.cadena.level.$error" class="invalid-feedback">
          <span v-if="!$v.cadena.level.required"> The Level is required</span>
        </div>
      </div>
      <div class="form-check form-switch mt-3">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="cadena.view_first">
        <label class="form-check-label" for="flexSwitchCheckDefault">View First</label>
      </div>

      <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
    </form>

  </b-modal>

</template>

<script>
import {required} from "vuelidate/lib/validators";
export default {
  name: "AddCadenas",
  props:['senario_id'],

  data: function() {
    return {
      typesubmit:false,
      cadena:{},

    }
  },
  validations: {
    cadena:{
      cadena_id: {required},
      level: {required},
    },
  },
  methods: {
    create(){
      this.typesubmit =false
      this.cadena = {view_first:true}
    },
    handleSubmit() {
      this.typesubmit = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.addCadena();

    },
    addCadena(){
      this.$http
          .post('senarios/'+this.senario_id+'/cadenas',this.cadena)
          .then(response => {
            this.$emit('refresh-add',response.data)
            this.$alertify.success(" success")
            this.typesubmit = false
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error(error.response.data.error)
          })

    },





  }


}
</script>


