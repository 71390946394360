<template>
  <b-sidebar id="indices" title="Indices" width="500px" bg-variant="dark" text-variant="light"
             @shown="create"  @hidden="close" right shadow backdrop backdrop-variant="transparent">

    <div class="px-3 py-2">
    <div class="card">
      <div class="card-header row mx-0">
          <button type="button" class="btn btn-secondary col-xl-3 me-2" v-b-modal.add-indice>Add Indice</button>

        <input type="number" placeholder="level" class="col-xl-3 ms-auto" v-model="level" @change="create"/>
        <select class="form-select form-select-sm col-xl-3 ms-auto" v-model="lang" @change="create">
          <option value="fr">Fr</option>
          <option value="en">En</option>
        </select>
      </div>

      <div class="card-body" >
        <table class="text-center" v-if="indices.length && load">

          <tbody>
          <tr v-for="(data,index) in indices" v-bind:key="data.id">
            <td data-label="Content">
              <i v-if="data.is_solution == 1" class="fas fa-lightbulb float-xl-start text-warning mx-1"></i>
              <audio controls v-if="data.type == 'audio'">
                <source :src="data.content" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
              <img v-else-if="data.type == 'image'" :src="data.content" width="100" height="100"/>
              <p class="text-white" v-else>{{data.content}}</p>
            </td>
            <td data-label="" style="min-width:80px" >
              <i class="fa fa-edit text-info me-2" @click="showEdit(data)"></i>
              <i class="fa fa-trash text-danger" @click="deleteItem(index)"></i>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="text-center text-white" v-if="load && indices.length == 0">No Data Found</div>
      </div>
    </div>
    </div>
    <add-indice :senario_id="senario_id" v-on:refresh-add="refreshAdd"/>
    <edit-indice :indice_p="indice"  v-on:refresh-edit="refreshEdit"/>
  </b-sidebar>
</template>

<script>
import AddIndice from "./AddIndice";
import EditIndice from "./EditIndice";
export default {
  name: "Indices",
  components: {EditIndice, AddIndice},
  props:['senario_id'],
  data(){
    return{
      indices:[],
      indice:{},
      lang:'fr',
      level:1,
      load:false
    }
  },
  methods:{
    create(){

      this.$http
          .get('senarios/'+this.senario_id+'/levels/'+this.level+'/indices/'+this.lang)
          .then(response => {

            this.indices = response.data
            this.load = true
          })
          .catch(error => {
            console.log(error)
          })
    },
    close(){
      this.load=false
      this.indices =[]
    },
    deleteItem(index){
      this.$http
          .delete('indices/'+this.indices[index].id)
          .then(() => {
            this.$alertify.success(" success")
            this.indices.splice(index,1)
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error("Error");
          })
    },
    refreshAdd(data){
     if(this.lang == data.lang && data.level == this.level)  this.indices.unshift(data)

    },
    refreshEdit(data){
      let index = this.indices.findIndex(ele => ele.id === this.indice.id);
      this.indices[index] = {...data}
      this.$forceUpdate();
    },
    showEdit(data){
      this.indice = data
      this.$root.$emit('bv::show::modal', 'edit-indice')
    }
  }
}
</script>
<style scoped  src="@/assets/table.css"></style>
<style scoped>
table td[data-v-4d2ed227] {
  border-top: 1px solid #ccc;
  color: #fff;
}
.card{
  background-color: inherit!important;
}
/deep/ .b-sidebar-header .close{
  background-color: inherit!important;
  border: none!important;
  color: white!important;
}

</style>