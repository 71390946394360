<template>
  <b-modal id="edit-video" ref="modal" centered title="Edit Video //"
           title-class="font-18" hide-footer  @shown="create"  hide-header-close>

    <div class="row">
      <form id="form" method="POST" @submit.prevent="handleSubmit">
        <div class="form-group mt-3 mb-0">
          <label class="pb-2">Youtube Video Id:</label>
          <b-form-input type="text" placeholder="Video" v-model="senario.video"
                        :class="{ 'is-invalid': typesubmit && $v.senario.video.$error }"></b-form-input>
          <div v-if="typesubmit && $v.senario.video.$error" class="invalid-feedback">
            <span v-if="!$v.senario.video.required"> The Video is required</span>
          </div>
        </div>
        <div class="row mx-0">
          <div class="form-group col-xl-6 mt-3 ms-0 mb-0">
            <label class="pb-2">Lang:</label>
            <b-form-radio-group
                v-model="senario.lang"
                :options="langs"
                class=" d-flex mt-2"
                value-field="item"
                text-field="name"
            ></b-form-radio-group>
          </div>
          <div class="form-group col-xl-6 mt-3 pe-0 mb-0">
            <label class="pb-2">Level:</label>
            <b-form-input type="text" placeholder="Level" v-model="senario.level"
                          :class="{ 'is-invalid': typesubmit && $v.senario.level.$error }"></b-form-input>
            <div v-if="typesubmit && $v.senario.level.$error" class="invalid-feedback">
              <span v-if="!$v.senario.level.required"> The Level is required</span>
            </div>
          </div>
        </div>
        <div class="form-group mt-3 mb-0">
          <label class="form-label">Image :</label>
          <input class="form-control form-control-sm" type="file" accept="image/*"  ref="image" />
        </div>
        <div class="form-group mt-3 mb-0">
          <label class="pb-2">Message:</label>
          <b-form-textarea rows="3" placeholder="Message" v-model="senario.message"></b-form-textarea>

        </div>
        <div class="row mx-0">
          <div class="form-group col-xl-6 mt-3 ms-0 mb-0">
          <b-form-checkbox
              id="checkbox-1"
              v-model="senario.is_continue"
              name="checkbox-1"
              :unchecked-value="0"
              :value="1"
          >
            Continue without code
          </b-form-checkbox>

          </div>
          <div class="form-group col-xl-6 mt-3 pe-0 mb-0">
          <b-form-checkbox
              id="checkbox-1"
              v-model="senario.is_dead"
              name="checkbox-1"
              :unchecked-value="0"
              :value="1"
          >
            Is Dead
          </b-form-checkbox>

        </div>
        </div>
        <div class="row mt-3 mx-0"  v-if="senario.level>1">
          <div class="col-xl-5 ms-auto mb-2">
            <label class="key me-3 key1"></label>Level Not Solved
          </div>
        </div>
        <div class="row mx-0"  v-if="senario.level>1">
          <div class="col-xl-5 ms-auto mb-2">
            <label class="key me-3 key2"></label>Level Solved
          </div>

        </div>
        <div class="row mx-0 my-3" v-if="senario.level>1">
          <div class="col-xl-1 col-2 mb-2 px-2 text-center" v-for=" n in senario.level-1" :key="n">
            <input type="checkbox" :id="'in-'+n" v-model="checks[n-1]" :true-value="1"
                   :false-value="0"/>
            <label class="l" :for="'in-'+n"></label>
            <label>{{n}}</label>
          </div>
        </div>


        <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
      </form>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "EditVideo",
  props:['senario_p'],
  data: function() {
    return {
      typesubmit:false,
      senario:{ },
      checks:[],
      langs: [
        { item: 'fr', name: 'Francais' },
        { item: 'en', name: 'Englais' },
      ]
    }
  },
  validations: {
    senario:{
      video: {required},
      level: {required},
    },
  },
  computed: {
    // a computed getter
    code: function () {
      // `this` points to the vm instance
     
        if(this.senario.level>1)  return this.checks.join('')
        else return '*'
      
    },

  },
  watch:{
    'senario.level':function(){

      if(this.senario_p.level!=this.senario.level)  return this.checks = new Array(this.senario.level-1).fill(0)
      else return  this.checks = this.senario.code.split("")
    },

    // `this` points to the vm instance

  },
  methods: {
    create(){
      this.typesubmit = false
      this.senario = {... this.senario_p}
      // this.checks = this.senario.split("")
      // this.code = this.senario.code

    },
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.editVideo();
    },
    editVideo(){
      this.senario.code = this.code
      let formData = new FormData();
      formData.append('video', this.senario.video);
      formData.append('lang', this.senario.lang);
      formData.append('level', this.senario.level);
      formData.append('code', this.code);
      formData.append('message', this.senario.message);
      formData.append('is_continue', this.senario.is_continue);
      formData.append('is_dead', this.senario.is_dead);
      if(this.$refs.image.files.length) formData.append('image', this.$refs.image.files[0]);
      this.$http
          .post('videos/'+this.senario.id,formData)
          .then(response=> {
            this.$emit('refresh-edit',response.data)
            this.$alertify.success(" success")
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
          })
    },
  }


}
</script>

<style scoped>
/deep/.custom-control{
  margin-right: 12px;
}
/deep/.custom-control-input{
  margin-right: 4px;
}
input[type=checkbox]{
  display: none;
}
.l {
  background: #ffb3b3;
  display: block;
  height: 48px;
  padding:4px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px #4b4b4b;
  transition: background 100ms ease-in-out;
}
.l::after {
  content: "";
  display: block;
  height: 17px;
  border-radius: 4px;
  background: #ff0000;
  box-shadow: inset 0px 0px 0px 2px #ff3333;
  transition: top 100ms ease-in-out, box-shadow 100ms ease-in-out, background 100ms ease-in-out;
}

input[type=checkbox]:checked ~ .l {
  background: #ccffcc;
}
input[type=checkbox]:checked ~ .l::after {
  background: #00ff00;
  box-shadow: inset 0px 0px 0px 2px #4dff4d;
}

.key {
  height: 17px;
  width: 17px;
  border-radius: 4px;
}
.key1{
  background: #ff0000;
  box-shadow: inset 0px 0px 0px 2px #ff3333;
}
.key2{
  background: #00ff00;
  box-shadow: inset 0px 0px 0px 2px #4dff4d;
}
</style>