<template>
  <main>
    <div class="row mt-5 m-0">
      <div class="col-xl-12 ">
        <div class="row mx-0">

        <h2 class="col-xl-6">Scenarios</h2>
          <div class="col-xl-3">
          <div class="searchBox float-end">
            <input class="searchInput" type="text" name="" v-model="filtre_title"   placeholder="Search">
            <button class="searchButton" href="#">
              <i class="fa fa-search">
              </i>
            </button>
          </div>

          </div>
          <div class="col-xl-3 d-flex">
            <button type="button" class="btn btn-secondary me-2" v-b-modal.add-senario>Add Scenario</button>
            <select class="form-select form-select-sm" v-model="lang" @change="loadSenarios">
              <option value="fr">Fr</option>
              <option value="en">En</option>
            </select>
          </div>
        </div>
        <div id="aspect-content">
          <div class="aspect-tab prio " v-for="(data,index) of senarios" :key="data.id">
            <input :id="data.id" type="checkbox" class="aspect-input" name="aspect">
            <label :for="data.id" class="aspect-label"></label>
            <div class="aspect-content">
              <div class="p-2 col-xl-12">
                <b-avatar class="me-2" :src="data.image"></b-avatar>
                <span class="aspect-name me-2">{{data.title}}</span>
                <b-badge class="badge rounded-pill bg-dark p-2">Cadenas : {{data.cadenas_count}}</b-badge>
                <div class="text-muted text-truncate mb-2">{{data.description}}</div>
              </div>
            </div>
            <div class="aspect-tab-content ">
              <div class="row mx-0 my-3">
                <div class="col-xl-2 form-check form-switch mx-3">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  v-model="data.is_available" @change="changeState(data.senario_id)">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Is Available</label>
                </div>
                <div class="col-xl-8">
                  <div class="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="button" class="btn btn-outline-primary" @click="Edit(data)">Edit Senario</button>
                    <button type="button" class="btn btn-outline-primary"  @click="addCadenas(data.senario_id)">Add Cadenas</button>
                    <button type="button" class="btn btn-outline-primary" v-b-toggle.indices @click="showIndices(data.senario_id)">Indices</button>
                    <button type="button" class="btn btn-outline-primary" v-b-toggle.videos @click="showVideos(data.senario_id)">Videos</button>
                  </div>
                </div>
                 <div class="col-xl-1 text-end">
                 <i style="cursor:pointer" class="fa fa-trash text-danger" @click="deleteSenario(index)"></i>
                 </div>

              </div>
              <div class="row m-0 py-2">

                <div class="col-xl-12"  v-if="data.cadenas_count">
                  <div class="card">

                    <div class="card-body" >
                      <table class="text-center">
                        <thead>
                        <tr>
                          <th scope="col" >Cadena</th>
                          <th scope="col">Level</th>
                          <th scope="col">Type</th>
                          <th scope="col">Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(ca,i) in data.cadenas" v-bind:key="ca.id">
                          <td data-label="Cadena">  <a :href="$url+'cadenas/'+ca.url_id" target="_blank">{{ca.name}}</a></td>
                          <td data-label="Level">{{ca.level}}</td>
                          <td data-label="Type">{{ca.type}}</td>
                          <td data-label="Code" >
                            <span class="badge bg-dark m-1 text-truncate p-2" v-for="data in ca.codes" :key="data.id" :title="data.code" style="max-width: 400px">{{data.code}}</span>
                          </td>
                          <td data-label="" >
                            <i class="fa fa-trash text-danger" @click="deleteItem(index,i)"></i>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
    <add-cadenas :senario_id="selected_id" v-on:refresh-add="refreshAdd"/>
    <edit-senario :senario_p="senario" v-on:refresh-edit="refreshEdit"/>
    <add-senario v-on:refresh-add="refreshAddSenario"/>
     <delete-confirmation type="senarios"  :id="senarios[index].senario_id" v-if="type == 'senario'" v-on:refresh-delete="refresh_delete"/>

    <delete-confirmation type="senarios"  :id="senarios[index].senario_id" v-else-if="senarios[index].cadenas.length" :id2="senarios[index].cadenas[index2].url_id" v-on:refresh-delete="refresh_delete"/>
    <indices :senario_id="senario_id"/>
    <videos :senario_id="senario_id"/>
  </main>
</template>

<script>
import AddCadenas from "@/components/AddCadenas";
import EditSenario from "@/components/EditSenario";
import AddSenario from "@/components/AddSenario";
import Indices from "../components/Indices";
import DeleteConfirmation from "../components/deleteConfirmation";
import Videos from "@/views/Videos";
export default {
  name: "Scenario",
  components: {Videos, DeleteConfirmation, Indices, AddSenario, EditSenario, AddCadenas},
  data(){
    return{
      senarios:[
        {
        senario_id:1,
        cadenas:
          [
              {url_id:1},
          ] 
        },
        ],
      senario:{},
      lang:'fr',

      selected_id:'',
      cadena_id:'',
      senario_id:'',
      type:'senario',
      index:'0',
      index2:'0',
      filtre_title:'',
    }
  },
  watch: {
    filtre_title: function () {
      this.filterSearch()
    },
  },
  created() {
    this.loadSenarios();
  },
  methods: {
    filterSearch() {
      this.$http
          .post('senarios/search',
              {
                title: this.filtre_title
              })
          .then(response => {
            this.senarios = response.data;
            this.$forceUpdate();

          })
          .catch(error => {
            console.log(error)
          })
    },

    loadSenarios() {
        this.senarios = []
        this.$http
          .get('senarios/'+this.lang )
          .then(response => {
            console.log(response.data)
            this.senarios = response.data;
            this.$forceUpdate();
          })
          .catch(error => {
            console.log(error)
          })
    },
    changeState(id){
      this.$http
          .post('senarios/' + id+'/state')
          .then(response => {
            this.$alertify.success(" success")
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error(" error")
          })
    },

    refreshAdd(data){
      let index = this.senarios.findIndex(ele => ele.senario_id === this.selected_id);
      this.senarios[index].cadenas_count += 1
      this.senarios[index].cadenas.unshift(data)
      this.$forceUpdate();
    },
    refreshAddSenario(data){
      if(data.lang == this.lang) this.senarios.unshift(data)
      this.$forceUpdate();
    },
    refreshEdit(data){
      console.log(data)
      let index = this.senarios.findIndex(ele => ele.senario_id === this.senario.senario_id);
      this.senarios[index] = data
      this.$forceUpdate();
    },
    showIndices(senario_id){
      this.senario_id= senario_id
      this.$root.$emit('bv::show::modal', 'indices')

    },
    showVideos(senario_id){
      this.senario_id= senario_id
      this.$root.$emit('bv::show::modal', 'videos')

    },
    deleteItem(index,index2) {
      this.index= index
      this.index2= index2
      this.type="senario_cadena"
      this.$root.$emit('bv::show::modal', 'modal-delete')

    },
    deleteSenario(index) {
      this.index= index
      this.type="senario"
      this.$root.$emit('bv::show::modal', 'modal-delete')

    },
    refresh_delete(){
     if(this.type=="senario_cadena"){
      this.senarios[this.index].cadenas_count -= 1
      this.senarios[this.index].cadenas.splice(this.index2,1)
      }
      else  this.senarios.splice(this.index,1)
      this.$forceUpdate();

    },
    addCadenas(id){
      this.selected_id= id
      this.$root.$emit('bv::show::modal', 'add-cadenas')

    },
    Edit(senario){
      this.senario = senario
      this.$root.$emit('bv::show::modal', 'edit-senario')
    }

  }

}
</script>
<style scoped src="@/assets/scene.css"></style>
<style scoped  src="@/assets/table.css"></style>
<style scoped>
.searchBox {
  background: #2f3640;
  height: 40px;
  border-radius: 40px;
}
.searchBox:hover > .searchInput {
  width: 150px;
  padding: 0 16px;
}
.searchBox:hover > .searchButton {
  background: white;
  color: #2f3640;
}
.searchButton {
  color: white;
  float: end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.searchInput {
  border: none;
  background: none;
  outline: none;
  float: start;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;

}

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 100px;
    padding: 0 14px;
  }
  .badge{
    max-width: 200px!important;
  }
}

.card{
  max-height: 300px;
  overflow-y: scroll;
}

html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
.card::-webkit-scrollbar {
  width: 8px;
}
.card {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.card::-webkit-scrollbar-track {
  background: none;
}
.card::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
}
/deep/ .form-select{
  width: fit-content!important;
}
/deep/.btn-group,/deep/ .btn-group-vertical {
  text-align: center;
  display: block;
}
</style>