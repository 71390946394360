<template>
  <b-sidebar id="videos" title="Videos" width="500px" bg-variant="dark" text-variant="light"
             @shown="create"  @hidden="close" right shadow backdrop backdrop-variant="transparent">

    <div class="px-3 py-2">
      <div class="card">
        <div class="card-header row mx-0">
          <button type="button" class="btn btn-secondary col-xl-3 me-2" v-b-modal.add-video>Add Video</button>
          <input type="number" placeholder="level" class="col-xl-3 ms-auto" v-model="level" @change="create"/>
        </div>

        <div class="card-body" >
          <table class="text-center" v-if="videos.length && load">
            <thead>
            <tr>
              <th scope="col" >#</th>
              <th scope="col" >Code</th>
              <th scope="col" >lang</th>
              <th scope="col"  >is continue</th>
              <th scope="col" ></th>
              <th scope="col" ></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,index) in videos" v-bind:key="index">
              <td data-label="#" >
                <b-avatar :src="data.image" :title="data.message" role="button"></b-avatar>
              </td>
              <td data-label="Code" class="text-truncate" style="max-width: 200px" >{{data.code}}</td>
              <td data-label="Lang">{{data.lang}}</td>
              <td data-label="is continue" style="max-width: 100px" >
                <span v-if="data.is_continue == 1">Yes</span>
                <span v-else>No</span>
              </td>
              <td data-label="Action">
                <a :href="'https://www.youtube.com/watch?v='+data.video" target="_blank"><i class="fab fa-youtube"  aria-hidden="true" type="button"></i></a>
              </td>
              <td data-label="" >
                <i class="fa fa-edit text-info me-2" @click="editItem(data)"></i>
                <i class="fa fa-trash text-danger" @click="deleteItem(index)"></i>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="text-center text-white" v-if="load && videos.length == 0">No Data Found in this level</div>
        </div>
      </div>
    </div>
    <add-video :senario_id="senario_id" v-on:refresh-add="refreshAdd"/>
    <edit-video  :senario_p="senario_p" v-on:refresh-edit="refreshEdit"/>
    <delete-video type="videos" v-if="videos.length"  :id="selected_id"  v-on:refresh-delete="videos.splice(index,1)"/>
  </b-sidebar>
</template>

<script>

import AddVideo from "@/components/AddVideo";

import DeleteVideo from "../components/delete-video";
import EditVideo from "@/components/EditVideo";
export default {
  name: "Videos",
  components: {EditVideo, DeleteVideo, AddVideo},
  props:['senario_id'],
  data(){
    return{
      videos:[],
      level:1,
      index:'',
      senario_p:{},
      selected_id:'',
      load:false
    }
  },
  methods:{
    create(){
      this.$http
          .get('senarios/'+this.senario_id+'/levels/'+this.level+'/videos')
          .then(response => {

            this.videos = response.data
            this.load = true
          })
          .catch(error => {
            console.log(error)
          })
    },
    close(){
      this.load=false
      this.videos =[]
    },
    deleteItem(index) {
      this.index= index
      this.selected_id= this.videos[index].id
      this.$root.$emit('bv::show::modal', 'delete-video')

    },
    editItem(data){
      this.senario_p= data
      this.$root.$emit('bv::show::modal', 'edit-video')
    },
    refreshAdd(data){
      this.level = data.level
      this.create()
    },
    refreshEdit(data){
      this.level = data.level
      let index = this.videos.findIndex(ele => ele.id === this.senario_p.id);
      this.videos.splice(index, 1);
      this.videos.splice(index, 0,data);
      this.$forceUpdate();
    },
  }
}
</script>
<style scoped  src="@/assets/table.css"></style>
<style scoped>
table td{
  border-top: 1px solid #ccc;
  color: #fff!important;
}
.card{
  background-color: inherit!important;
}
/deep/ .b-sidebar-header .close{
  background-color: inherit!important;
  border: none!important;
  color: white!important;
}

</style>