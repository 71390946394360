<template>
  <b-modal id="add-senario" ref="modal" centered title="Add Scenario //"
           title-class="font-18" hide-footer @shown="create" hide-header-close>

        <div class="row">
          <form id="form" method="POST" @submit.prevent="handleSubmit">
            <div class="form-group mt-3 mb-0">
              <label class="pb-2"> Title:</label>
              <b-form-input type="text" placeholder="Title" v-model="senario.title"
                            :class="{ 'is-invalid': typesubmit && $v.senario.title.$error }"></b-form-input>
              <div v-if="typesubmit && $v.senario.title.$error" class="invalid-feedback">
                <span v-if="!$v.senario.title.required"> The Title is required</span>
              </div>
            </div>
            <div class="form-group mt-3 mb-0">
              <label class="pb-2">Description:</label>
              <b-form-textarea rows="3"  v-model="senario.description"  :class="{ 'is-invalid': typesubmit && $v.senario.description.$error }"></b-form-textarea>
              <div v-if="typesubmit && $v.senario.description.$error" class="invalid-feedback">
                <span v-if="!$v.senario.description.required"> The Description is required</span>
              </div>
            </div>
            <div class="form-group mt-3 mb-0">
              <label class="pb-2">Password:</label>
              <b-form-input type="text"  v-model="senario.password" ></b-form-input>

            </div>
            <div class="row mx-0">

              <b-form-radio-group
                  v-model="senario.lang"
                  :options="langs"
                  class="form-group d-flex col-xl-6 mt-3 ms-0 mb-0"
                  value-field="item"
                  text-field="name"
              ></b-form-radio-group>

            <div class="form-check col-xl-6 me-0 form-switch mt-3">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="senario.is_available"
                     :value="1" :unchecked-value="0">
              <label class="form-check-label" for="flexSwitchCheckDefault">Is Available</label>
            </div>
            </div>
            <div class="form-group mt-3 mb-0">
              <label class="form-label">Image :</label>
              <input class="form-control form-control-sm" type="file" accept="image/*"  ref="image" />
            </div>
            <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
          </form>
        </div>

  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "AddSenario",
  data: function() {
    return {
      typesubmit:false,
      senario:{},
      langs: [
        { item: 'fr', name: 'Francais' },
        { item: 'en', name: 'Englais' },
      ]

    }
  },
  validations: {
    senario:{
      title: {required},
      description: {required},
    },

  },
  methods: {
    create(){
      this.typesubmit = false
      this.senario = {lang:'fr'}

    },
    handleSubmit() {

     this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.addSenario();
    },
    addSenario(){

      let formData = new FormData();
      formData.append('title', this.senario.title);
      formData.append('lang', this.senario.lang);
      formData.append('description', this.senario.description);
      if(this.senario.password)  formData.append('password', this.senario.password);
      if(this.senario.is_available) formData.append('is_available', 1);
      else formData.append('is_available', 0);
      if(this.$refs.image.files.length) formData.append('image', this.$refs.image.files[0]);
      this.$http
          .post('senarios',formData)
          .then(response => {
            let senario = response.data
            senario.cadenas_count = 0
            senario.cadenas = []
            this.$emit('refresh-add',senario)
            this.$alertify.success(" success")
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
          })
    },
  }


}
</script>

<style scoped>
/deep/.custom-control{
  margin-right: 12px;
}
/deep/.custom-control-input{
  margin-right: 4px;
}
</style>