<template>
  <b-modal id="add-indice" ref="modal" centered title="Add Indice //"
           title-class="font-18" hide-footer @shown="create" hide-header-close>
        <div class="row">
          <form id="form" method="POST" @submit.prevent="handleSubmit">
            <div class="row mx-0">

            <div class="col-xl-6 ps-0 form-group mt-3 mb-0">
              <label class="pb-2"> Level:</label>
              <b-form-input type="number" placeholder="Level" v-model="indice.level"></b-form-input>
              <div v-if="typesubmit && $v.indice.level.$error" class="invalid-feedback">
                <span v-if="!$v.indice.level.required"> The Level is required</span>
              </div>
            </div>
              <div class="form-group col-xl-6 mt-3 me-0 mb-0">
                <label class="pb-2">Lang:</label>
                <b-form-radio-group
                    v-model="indice.lang"
                    :options="langs"
                    class=" d-flex mt-2"
                    value-field="item"
                    text-field="name"
                ></b-form-radio-group>
              </div>
            </div>
            <div class="row mx-0">
            <div class="form-group col-xl-6 mt-3 ms-0 mb-0">
              <label class="pb-2"> Type:</label>
              <b-form-select class="form-select form-select-sm" :options="['text','audio','image']" type="text" placeholder="Type" v-model="indice.type"></b-form-select>
              <div v-if="typesubmit && $v.indice.type.$error" class="invalid-feedback">
                <span v-if="!$v.indice.type.required"> The Type is required</span>
              </div>
            </div>
            <div class="form-group col-xl-6 mt-3 ms-0 mb-0 d-flex">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="indice.is_solution"
                    name="checkbox-1"
                    class="me-2 align-self-end"
                    :unchecked-value="0"
                    :value="1"
                >
                  Solution
                </b-form-checkbox>

              </div>
              </div>

            <div class="form-group mt-3 mb-0">
              <label class="pb-2">Content:</label>
              <input v-if="indice.type == 'audio'"  class="form-control form-control-sm" type="file" accept="audio/*"  ref="file" />
              <input  v-else-if="indice.type == 'image'" class="form-control form-control-sm" type="file" accept="image/*"  ref="file" />
              <b-form-textarea rows="3" v-else  v-model="indice.content"></b-form-textarea>
            </div>

            <div class="row mx-0">
              <div class="form-group col-xl-6 mt-3 ms-0 mb-0">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="indice.mode0"
                    name="checkbox-1"
                    class="me-2"
                    :unchecked-value="0"
                    :value="1"
                >
                  Confirmed Mode
                </b-form-checkbox>

              </div>
              <div class="form-group col-xl-6 mt-3 pe-0 mb-0">
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="indice.mode1"
                    name="checkbox-1"
                    :unchecked-value="0"
                    class="me-2"
                    :value="1"
                >
                  Beginner Mode
                </b-form-checkbox>

              </div>
            </div>

            <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
          </form>
        </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "AddIndice",
  props:['senario_id'],
  data: function() {
    return {
      typesubmit:false,
      indice:{},
      langs: [
        { item: 'fr', name: 'Francais' },
        { item: 'en', name: 'Englais' },
      ]

    }
  },
  validations: {
    indice:{
      type: {required},
      level: {required},
    },
  },
  methods: {
    create(){
      this.typesubmit = false
      this.indice = {type:'text',lang:'fr',mode0:1,mode1:1}
    },
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.addIndice();
    },
    addIndice(){
      let formData = new FormData();
      formData.append('type', this.indice.type);
      formData.append('level', this.indice.level);
      formData.append('lang', this.indice.lang);
      formData.append('mode0', this.indice.mode0);
      formData.append('mode1', this.indice.mode1);
      formData.append('is_solution', this.indice.is_solution);
      if(this.indice.type == 'audio' || this.indice.type == 'image') {
        if(this.$refs.file.files.length) formData.append('content', this.$refs.file.files[0]);
      }
      else formData.append('content', this.indice.content);
      this.$http
          .post('senarios/'+this.senario_id+'/indices',formData)
          .then(response => {
            this.$emit('refresh-add',response.data)
            this.$alertify.success(" success")
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
          })
    },
  }


}
</script>

<style scoped>
/deep/.custom-control{
  margin-right: 12px;
}
/deep/.custom-control-input{
  margin-right: 4px;
}
</style>