<template>
  <b-modal id="edit-senario" title="Edit Scenario ///" ref="modal" title-class="font-22"
           hide-footer @shown="create" hide-header-close>
    <form id="form" method="POST" @submit.prevent="handleSubmit">
      <div class="form-group mt-3 mb-0">
        <label class="pb-2"> Title:</label>
        <b-form-input type="text" placeholder="Title" v-model="senario.title"
                      :class="{ 'is-invalid': typesubmit && $v.senario.title.$error }"></b-form-input>
        <div v-if="typesubmit && $v.senario.title.$error" class="invalid-feedback">
          <span v-if="!$v.senario.title.required"> The Title is required</span>
        </div>


      </div>
      <div class="form-group mt-3 mb-0">
        <label class="pb-2">Description:</label>
        <b-form-textarea rows="3"  v-model="senario.description"  :class="{ 'is-invalid': typesubmit && $v.senario.description.$error }"></b-form-textarea>
        <div v-if="typesubmit && $v.senario.description.$error" class="invalid-feedback">
          <span v-if="!$v.senario.description.required"> The Description is required</span>
        </div>
      </div>
      <div class="form-group mt-3 mb-0">
        <label class="pb-2">Password:</label>
        <b-form-input type="text"  v-model="senario.password" ></b-form-input>

      </div>
      <div class="form-group mt-3 mb-0">
        <label class="form-label">Update Image :</label>
        <input class="form-control form-control-sm" type="file" accept="image/*"  ref="image" />
      </div>

      <input type="submit" class="btn btn-primary px-3 float-end mt-3 mx-auto" variant="primary" value="Save"/>
    </form>

  </b-modal>

</template>

<script>
import {required} from "vuelidate/lib/validators";
export default {
  name: "EditSenario",
  props:['senario_p'],

  data: function() {
    return {
      typesubmit:false,
      senario:{},


    }
  },
  validations: {
    senario:{
      title: {required},
      description: {required},
    },
  },
  methods: {
    create(){
      this.senario = { ...this.senario_p}
    },
    handleSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.editSenario();
    },
    editSenario(){
      let formData = new FormData();
      formData.append('title', this.senario.title);
      formData.append('description', this.senario.description);
      if(this.senario.password)  formData.append('password', this.senario.password);
      if(this.$refs.image.files.length) formData.append('image', this.$refs.image.files[0]);
      this.$http
          .post('senarios/'+this.senario.senario_id,formData)
          .then(response => {

            this.$emit('refresh-edit',response.data)
            this.$alertify.success(" success")
            this.$refs['modal'].hide()
          })
          .catch(error => {
            console.log(error)
          })
    },
  }


}
</script>


